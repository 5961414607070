<template>
  <v-container
    fluid
  >

    <v-flex d-flex>
      <v-layout wrap>
        <v-flex 
          md4 
          v-for="group in groups"
          :key="group.groupid"
          pa-2         
        >
          <groupCard 
            :group="group"
            @renameGroup="renameGroup"
          ></groupCard>
        </v-flex>
      </v-layout>
    </v-flex>

  </v-container>
</template>

<script>
  import errorUtils from '@/utils/errorUtils'
  import { RepositoryFactory } from '@/repositories/RepositoryFactory'

  const GroupRepository = RepositoryFactory.get('group');

  export default {
    name: 'MyGroups',

    components: {
      groupCard: () => import("@/components/GroupCard"),
    },

    data: () => ({
      groups: [],
    }),

    mounted() {
      this.loadGroups();
    },

    methods: {

      async loadGroups() {
        const { data } = await GroupRepository.listMyGroups();
        if (data.success) {               
          this.groups = data.data.groups;
        } else {
          errorUtils.showError(data.message);
        }
      },

      async renameGroup(groupId, name) {      
        const { data } = await GroupRepository.renameGroup(groupId, name);
        if (data.success) {               
          this.loadGroups();
        } else {
          errorUtils.showError(data.message);
        }
      },

    },

  }
</script>
